import React from 'react'
import { Link } from "react-router-dom"
import Header from './header'
import { Row, Col, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();
  return (
    <><Header />
    <Row align='middle' justify="center" className="Register" >
      <Col xs={24} md={24} lg={24} align='center'>
    <Typography.Title level={3}>LogIn</Typography.Title>
    </Col>
    <Col xs={24} md={24} lg={24}>
    <form className="form" onSubmit={()=>{navigate('/admin')}}>
        <input className="input-content" type="email" name="email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}"
            placeholder="Email" required />
        <input className="input-content" type="password" name="password" placeholder="Password" required />
        {/* <!-- <button className="submit-button" type="submit"><a href="./Admin.html">LogIn</a></button> -->
        <!-- <input className="submit-button"type="submit"  > --> */}
        <button className="submit-button">LogIn</button>
       

    </form>
    </Col>
    <Col xs={24} md={12} lg={12} align='left'>
    <Typography.Text level={3}>Donot have an account? <Link to='/registration'>Register</Link>  <Link to='/forgotpassword'>ForgotPassword</Link></Typography.Text>
    </Col>
</Row>
</>
  )
}

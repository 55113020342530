import React from 'react'
import TreeList from '../components/NestedTabs'
import AdminHeader from './admin-header'
export default function Resident() {
  return (
    <div>
      <AdminHeader headerContent ={<section className="container hero row">
            <div className="content">
                <h1>Hello Resident</h1>
            </div>
        </section>}/>
        <TreeList />
    </div>
  )
}

import { Row,Col,Typography } from 'antd'
import React from 'react'
import AdminHeader from './admin-header'

export default function Admin() {
  return (
    <div>
      <AdminHeader />
      <Row className="admin-page">
        <Col span={3} className="side-nav">
          <ul>
            <li>
              <select className="input-content" name="select">
                <option value="Security">Security</option>
              </select>
            </li>
            <hr />
            <li>
              <select className="input-content" name="select">
                <option value="Item1">Funds</option>
              </select>
            </li>
            <hr />
            <li>
              <select className="input-content" name="select">
                <option value="Item1">Tax</option>
              </select>
            </li>
            <hr />
            <li>
              <select className="input-content" name="select">
                <option value="Item1">Prices</option>
              </select>
            </li>
          </ul>
        </Col>
        <Col span={20}>
          <Row justify={'start'}>
            <Col span={4} className="card card-1">
              <h3>Security Deposits</h3>
              <h2><span>$</span>20,0000</h2>
            </Col>
            <Col span={4} className="card card-2">
              <h3>Employee Salary</h3>
              <h2><span>$</span>22,5445</h2>
            </Col>
            <Col span={4} className="card card-3">
              <h3>Renovation funds</h3>
              <h2><span>$</span>30,04544</h2>
            </Col>
            <Col span={4} className="card card-4">
              <h3>Total Tax collected</h3>
              <h2><span>$</span>70,45440</h2>
            </Col>
            <Col span={4} className="card card-5">
              <h3>Selling price</h3>
              <h2><span>$</span>75,6568</h2>
            </Col>
          </Row>
          <div className="chart-section">
            <h2 style={{textAlign: 'center', color: 'black !important', margin: '20px 0 0 0'}}>chart</h2>
            <div className="chart" style={{display: 'flex'}}>
              <div style={{backgroundColor: '#042648', width: '50px', height: '300px', marginRight: '25px'}} />
              <div style={{backgroundColor: '#042648', width: '50px', height: '200px', marginRight: '25px'}} />
              <div style={{backgroundColor: '#042648', width: '50px', height: '250px', marginRight: '25px'}} />
              <div style={{backgroundColor: '#042648', width: '50px', height: '150px', marginRight: '25px'}} />
              <div style={{backgroundColor: '#042648', width: '50px', height: '50px', marginRight: '25px'}} />
              <div style={{backgroundColor: '#042648', width: '50px', height: '165px', marginRight: '25px'}} />
              <div style={{backgroundColor: '#042648', width: '50px', height: '105px'}} />
            </div>
          </div>
          <div className="admin-tables">
            <div className="tables">
              <h1 className="table-heading">Manage Buildings</h1>
              <table id="manageBulding">
                <tbody><tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Community</th>
                    <th>Floors</th>
                    <th>Occupancy</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Rock</td>
                    <td>RMZ</td>
                    <td>40</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Water</td>
                    <td>brigade</td>
                    <td>30</td>
                    <td>30%</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Forest</td>
                    <td>Sobha</td>
                    <td>100</td>
                    <td>80%</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Lake</td>
                    <td>RMZ</td>
                    <td>35</td>
                    <td>100%</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Hill</td>
                    <td>Sobha</td>
                    <td>200</td>
                    <td>20%</td>
                  </tr>
                </tbody></table>
              <button className="table-button">See More</button>
            </div>
            <div className="tables">
              <h1 className="table-heading">Manage Timings</h1>
              <table id="manageBulding">
                <tbody><tr>
                    <th>Days</th>
                    <th>Name</th>
                    <th>Rules</th>
                    <th>Timings</th>
                  </tr>
                  <tr>
                    <td>Mon-Fri</td>
                    <td>Vistors parking</td>
                    <td>Registration required</td>
                    <td>All time</td>
                  </tr>
                  <tr>
                    <td>Sat-Sun</td>
                    <td>Vistors parking</td>
                    <td>Registration required</td>
                    <td>All time</td>
                  </tr>
                  <tr>
                    <td>Sat-Sun</td>
                    <td>party room</td>
                    <td>Registration required</td>
                    <td>8pm-2am</td>
                  </tr>
                  <tr>
                    <td>All days</td>
                    <td>Gym room</td>
                    <td>Registration required</td>
                    <td>4am-12pm</td>
                  </tr>
                  <tr>
                    <td>All days</td>
                    <td>Game room</td>
                    <td>Registration required</td>
                    <td>8am-8pm</td>
                  </tr>
                  <tr>
                    <td>All days</td>
                    <td>study carrel</td>
                    <td>Registration required</td>
                    <td>24/7</td>
                  </tr>
                </tbody></table>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

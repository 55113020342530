import React, {useState } from 'react';
import { Tree } from 'antd';
import Join from './join';
import Membership from './membership';
import { Row,Col} from 'antd';
const { TreeNode } = Tree;

const treeData = [
  {
    title: 'Join/Leave membership',
    key: '0-0',
    description:<Join/>,
    children: [
      { title: 'party room', key: '0-0-0', description: <Membership /> },
      { title: 'Manage Building', key: '0-0-1', description: <Membership /> },
      { title: 'Manager Visitor', key: '0-0-2', description: <Membership /> },
      { title: 'Resident', key: '0-0-3', description: <Membership /> },

    ],
  },
  {
    title: 'Party Room',
    key: '0-1',
    description:<Join/>,
    children: [
      { title: '8pm-2am weekends', key: '0-1-0', description: <Membership /> },
      { title: 'Capacity 100 people', key: '0-1-1', description: <Membership /> },
      { title: 'Room number 101', key: '0-1-2', description: <Membership /> },
    ],
  },
  {
    title: 'Pool Area',
    key: '0-2',
    description:<Join/>,
    children: [
      { title: '8pm-2am weekends', key: '0-1-0', description: <Membership /> },
      { title: 'Capacity 40 people', key: '0-1-1', description: <Membership /> },
      { title: 'Access key required', key: '0-1-2', description: <Membership /> },
    ],
  },
  {
    title: 'Game Room',
    key: '0-3',
    description:<Join/>,
    children: [
      { title: '8am-8pm Everyday', key: '0-1-0', description: <Membership /> },
      { title: 'Capacity 20 members', key: '0-1-1', description: <Membership /> },
      { title: 'Access key required', key: '0-1-2', description: <Membership /> },
    ],
  },
  {
    title: 'Gym',
    key: '0-4',
    description:<Join/>,
    children: [
      { title: '4am-12pm Everyday', key: '0-1-0', description: <Membership /> },
      { title: 'Capacity 50 members', key: '0-1-1', description: <Membership /> },
    ],
  },
  {
    title: 'Study Carrel',
    key: '0-4',
    description:<Join/>,
    children: [
      { title: '24/7 Everyday', key: '0-1-0', description: <Membership /> },
      { title: 'Capacity 15 members', key: '0-1-1', description: <Membership /> },
    ],
  }
];

function DetailView({ selectedNode }) {
    console.log(selectedNode)
  if(selectedNode !== null && selectedNode && selectedNode[0] === '0-0'){
    return <div className="detail-view" key={treeData[0].key}>
        <h2>{treeData[0].title}</h2>
         <div>{treeData[0].description}</div>
       </div>;
  }else if(selectedNode === null){
    return <div className="detail-view" key={treeData[0].key}>
    <h2>{treeData[0].title}</h2>
     <div>{treeData[0].description}</div>
   </div>;
  }else {
  return (
    <div className="detail-view" key={selectedNode.key}>
      <h2>{selectedNode.title}</h2>
      <div>{selectedNode.description}</div>
    </div>
  );
}
}

function TreeList() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  const handleSelect = (selectedKeys, { node }) => {
    setSelectedKeys(selectedKeys);
    setSelectedNode(node);
    console.log('Selected:', selectedKeys);
  };

  return (
    <Row className="tree-container">
      <Col span={8}>
      <Tree
        className="tree-list"
        showLine
        treeData={treeData}
        selectedKeys={selectedKeys}
        onSelect={handleSelect}
        defaultExpandAll
      >
        {treeData.map(({ title, key, children,description }) => (
          <TreeNode title={title} key={key} description={description}>
            {children.map(({ title, key, description }) => (
              <TreeNode title={title} key={key} description={description} />
            ))}
          </TreeNode>
        ))}
      </Tree>
      </Col>
      <Col span={15} align='center'>
      <DetailView selectedNode={selectedNode} />
      </Col>
    </Row>
  );
}

export default TreeList;

import React from 'react'
import { Link } from 'react-router-dom'
import Header from './header'
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography } from 'antd';
export default function Registration() {
    const navigate = useNavigate();
  return (
    <><Header />
    <Row className="form-container" align='middle' justify="center">
          <Col xs={24} md={24} lg={24} align='middle'>
          <Typography.Title level={3} >Register</Typography.Title>
          </Col>
          <Col xs={24} md={24} lg={24}>
          <form className="form" onSubmit={()=>navigate('/login')}>
              <input className="input-content" type="text" name="firstname" minLength="3" pattern="^[A-Za-z]+([\ A-Za-z]+)*$"
                  placeHolder="Name" required />
              <input className="input-content" type="email" name="email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}"
                  placeHolder="Email" required />
              <input className="input-content" type="tel" name="phone" pattern="[0-9]{10}" placeHolder="Phone Number"
                  required />
              <input className="input-content" type="date" name="DOB" placeHolder="Date Of Birth" required />
              <input className="input-content" type="password" name="password" placeHolder="Password" required />
              {/* <!-- <button className="submit-button" type="submit"><a href="./Login.html">Register</a></button>  -->
    <!-- <input className="submit-button" type="submit"> --> */}
              <button className="submit-button">Register</button>
          </form>
          {/* <!-- <button className="submit-button"><a href="./Login.html">Register</a></button>  --> */}
          <Typography.Text level={3} >Already have an account? <Link to='/login'>Login</Link></Typography.Text>
          </Col>
      </Row></>
  )
}

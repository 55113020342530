import React from 'react'
import '../styles/_home.scss'
import Header from './header'
import { Row, Col } from 'antd';
export default function Home() {
  return <>
  <Header headerContent={<div class="content">
              <h1> <marquee>Welcome to..!</marquee></h1>
              <h1><marquee direction="right" behavior="alternate">Terrazas de Guacuco</marquee></h1>
              </div>} />
              <Row class="table-section" justify="space-around" align="middle">
              <Col align='center' span={10} xs={24} md={24} lg={10} class="tables">
              <h1 class="table-heading">Manage Buildings</h1>
              <table id="manageBulding">
                  <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Community</th>
                      <th>Floors</th>
                      <th>Occupancy</th>
                  </tr>
                  <tr>
                      <td>1</td>
                      <td>Rock</td>
                      <td>RMZ</td>
                      <td>40</td>
                      <td>50%</td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>Water</td>
                      <td>brigade</td>
                      <td>30</td>
                      <td>30%</td>
                  </tr>
                  <tr>
                      <td>3</td>
                      <td>Forest</td>
                      <td>Sobha</td>
                      <td>100</td>
                      <td>80%</td>
                  </tr>
                  <tr>
                      <td>4</td>
                      <td>Lake</td>
                      <td>RMZ</td>
                      <td>35</td>
                      <td>100%</td>
                  </tr>
                  <tr>
                      <td>1</td>
                      <td>Hill</td>
                      <td>Sobha</td>
                      <td>200</td>
                      <td>20%</td>
                  </tr>
              </table>
              <button class="table-button">See More</button>
          </Col>
          <Col span={12} xs={24} md={24} lg={10} class="tables">
              <h1 class="table-heading">Manage Timings</h1>
              <table id="manageBulding">
                  <tr>
                      <th>Days</th>
                      <th>Name</th>
                      <th>Rules</th>
                      <th>Timings</th>

                  </tr>
                  <tr>
                      <td>Mon-Fri</td>
                      <td>Vistors parking</td>
                      <td>Registration required</td>
                      <td>All time</td>
                  </tr>
                  <tr>
                      <td>Sat-Sun</td>
                      <td>Vistors parking</td>
                      <td>Registration required</td>
                      <td>All time</td>
                  </tr>
                  <tr>
                      <td>Sat-Sun</td>
                      <td>party room</td>
                      <td>Registration required</td>
                      <td>8pm-2am</td>
                  </tr>
                  <tr>
                      <td>All days</td>
                      <td>Gym room</td>
                      <td>Registration required</td>
                      <td>4am-12pm</td>
                  </tr>
                  <tr>
                      <td>All days</td>
                      <td>Game room</td>
                      <td>Registration required</td>
                      <td>8am-8pm</td>
                  </tr>
                  <tr>
                      <td>All days</td>
                      <td>study carrel</td>
                      <td>Registration required</td>
                      <td>24/7</td>
                  </tr>
              </table>

          </Col>
      </Row></>
}

import React from 'react'
import { useNavigate } from "react-router-dom";
import { Row,Col, Typography} from 'antd';
export default function Membership() {
    const navigate = useNavigate();
  return (
    <Row align='middle' justify="center" className="form-container" >
      <Col xs={24} md={24} lg={24} align='center'>
    <Typography.Title level={3}>Memebership</Typography.Title>
    </Col>
    <Col xs={24} md={24} lg={24} align='center'>
      <form className="form" onSubmit={()=>{navigate('/resident')}}>
          <input className="input-content" type="email" name="email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}" placeholder="Email" required />
          <input className="input-content" type="password" name="password" placeholder="Password" required />
          <input className="input-content" type="text" name="roomnumber" placeholder="Room Number" required />
        </form>
        </Col>
        <Col xs={24} md={24} lg={24} align='center' justify="space-between">
        <button className="submit-button-member" type="submit">Join</button>
          <button className="submit-button-member" type="submit">Leave</button>
          </Col>
      </Row>
  )
}

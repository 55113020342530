import React from 'react'

export default function Join() {
  return (
    <div>
      <div className="ManagerBuildingTables">
    <div className="tables">
      <h1 className="table-heading">Service CURD</h1>
      <table id="manageBulding">
        <tbody><tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Community</th>
            <th>Floors</th>
            <th>Occupancy</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Rock</td>
            <td>RMZ</td>
            <td>40</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Water</td>
            <td>brigade</td>
            <td>30</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Forest</td>
            <td>Sobha</td>
            <td>100</td>
            <td>80%</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Lake</td>
            <td>RMZ</td>
            <td>35</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Hill</td>
            <td>Sobha</td>
            <td>200</td>
            <td>20%</td>
          </tr>
        </tbody></table>
      <button className="table-button">See More</button>
    </div>
    <div className="tables">
      <h1 className="table-heading">Calender</h1>
      <div className="month">
        <ul>
          <li className="prev">❮</li>
          <li className="next">❯</li>
          <li>
            August<br />
            <span style={{fontSize: '18px'}}>2021</span>
          </li>
        </ul>
      </div>
      <ul className="weekdays">
        <li>Mo</li>
        <li>Tu</li>
        <li>We</li>
        <li>Th</li>
        <li>Fr</li>
        <li>Sa</li>
        <li>Su</li>
      </ul>
      <ul className="days">
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
        <li>5</li>
        <li>6</li>
        <li>7</li>
        <li>8</li>
        <li>9</li>
        <li><span className="active">10</span></li>
        <li>11</li>
        <li>12</li>
        <li>13</li>
        <li>14</li>
        <li>15</li>
        <li>16</li>
        <li>17</li>
        <li>18</li>
        <li>19</li>
        <li>20</li>
        <li>21</li>
        <li>22</li>
        <li>23</li>
        <li>24</li>
        <li>25</li>
        <li>26</li>
        <li>27</li>
        <li>28</li>
        <li>29</li>
        <li>30</li>
        <li>31</li>
      </ul>
    </div>
    <div className="tables">
      <h1 className="table-heading">Register Vehicle</h1>
      <form className="form">
        <input className="input-content" type="text" name="firstname" placeholder="First name.." />
        <input className="input-content" type="text" name="lastname" placeholder="Last name.." />
        <input className="input-content" type="text" name="VechileNo" placeholder="Vechile Number" />
        <textarea className="input-content" name="subject" placeholder="Write something.." style={{height: '200px'}} defaultValue={""} />
        <button className="submit-button">See More</button>
      </form>
    </div>
    <div className="tables">
      <h1 className="table-heading">Manage Buildings</h1>
      <table id="manageBulding">
        <tbody><tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Community</th>
            <th>Floors</th>
            <th>Occupancy</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Rock</td>
            <td>RMZ</td>
            <td>40</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Water</td>
            <td>brigade</td>
            <td>30</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Forest</td>
            <td>Sobha</td>
            <td>100</td>
            <td>80%</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Lake</td>
            <td>RMZ</td>
            <td>35</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Hill</td>
            <td>Sobha</td>
            <td>200</td>
            <td>20%</td>
          </tr>
        </tbody></table>
      <button className="table-button">See More</button>
    </div>
    <div className="tables">
      <h1 className="table-heading">Chat</h1>
      <form className="form">
        <input className="input-content" type="text" name="firstname" placeholder="First name.." />
        <input className="input-content" type="text" name="lastname" placeholder="Last name.." />
        <input className="input-content" type="text" name="roomno" placeholder="Room No" />
        <select className="input-content" name="select">
          <option value="Item1">Issues</option>
          <option value="Item1">In house repairs</option>
          <option value="Item2">lease extenstion</option>
          <option value="Item3">others</option>
        </select>
        <textarea className="input-content" name="subject" placeholder="Write something.." style={{height: '200px'}} defaultValue={""} />
        <button className="submit-button">submit</button>
      </form>
    </div>
  </div>
    </div>
  )
}

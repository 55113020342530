import React from 'react'
import Header from './header'

export default function ForgetPassword() {
  return (
    <><Header/>
    <div className="Register">
    <h3>Forgot Password</h3>

    <form className="form">
        <input className="input-content" type="email" name="email" placeholder="Email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}" required />
        <input className="input-content" type="password" name="password" placeholder="New Password" required />
        <input className="input-content" type="password" name="password" placeholder="Re-Enter New Password" required />
        {/* <!-- <button className="submit-button" type="submit"><a href="./Home.html">LogIn</a></button> --> */}
        <input className="submit-button" type="submit" />

    </form>
    {/* <!-- <button className="submit-button"><a href="./Home.html">LogIn</a></button>  --> */}
    <h6>Have an account?<a href="./Login.html">Login</a></h6>

</div>
</>
  )
}

import React from 'react'
import Header from './header'
import { Row, Col, Typography } from 'antd';
export default function Contact() {
  return (
    <>
    <Header headerContent ={<div className="content">
                <h1>Contact Us</h1>
            </div>} />
    <Row className="form-container" align={'center'}>
        <Col xs={22} md={22} lg={22} align='center'>
        <Typography.Title level={3} >Contact Form</Typography.Title>
        </Col>
        <Col xs={22} md={22} lg={22}>
        <form className="form" action="./Index.html">
            <label>First Name</label>
            <input className="input-content" type="text" name="firstname" minLength="3" pattern="^[A-Za-z]+([\ A-Za-z]+)*$"
                placeHolder="First name.." required />
            <label>Last Name</label>
            <input className="input-content" type="text" name="lastname" pattern="^[A-Za-z]+([\ A-Za-z]+)*$"
                placeHolder="Last name.." required />

            <label>Email</label>
            <input className="input-content" type="email" name="email" pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}"
                placeHolder="Email.." required />

            <label>Phone Number</label>
            <input className="input-content" type="tel" name="phone" pattern="[0-9]{10}" placeHolder="Phone Number.."
                required />

            <label>Subject</label>
            <textarea className="input-content" name="subject" placeHolder="Write something.." style={{height:'200px'}}
                required></textarea>
            <button className="submit-button">Submit</button>
        </form>
        </Col>
    </Row>
    </>
  )
}

import React from 'react'
import { Link } from "react-router-dom"
import { Row,Col} from 'antd';
export default function AdminHeader(props) {
  return (
    <div>
       <header className='header-bg'>
       <Row justify="space-between" align="middle">
       <Col span={4} xs={10} md={10} lg={10}>
       <Link to='/' className="logo">
            <h3>Terrazas de Guacuco</h3>
        </Link>
        </Col>
        <Col span={6} xs={14} md={14} lg={14}>
        <div className="toggleMenu" onclick="toggleMenu();">        </div>
        <Row justify="space-between" align="middle">
        <Col span={18} xs={18} md={18} lg={18} align='left'>
            <ul className="primary-list row">
            <Link to='/admin'>Admin Page</Link>
            <Link to='/manage-building'>Manage Building</Link>
            <Link to='/manage-visitor'>Visitor</Link>
            <Link to='/resident'>Resident</Link>
            </ul>
            </Col>
            <Col span={3} xs={3} md={3} lg={3}>
            <ul className="secondary-list row">
                <li><button className="loginbtn"><Link to='/registration'>Login/Register</Link></button></li>
            </ul>
            </Col>
        </Row>
        </Col>
        </Row>
    </header>
    <Row className="container hero row">
        <Col>
          {props.headerContent}
          </Col>
          </Row>
    </div>
  )
}

